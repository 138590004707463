import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "business-Michael-Gonzalez-Rodriguez-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Michael (Mike) Gonzalez Rodriguez`}</h1>
    <p>{`Costa Rica`}</p>
    <p>{`He worked full and part time on my team from September 2019 until May 2020.`}</p>
    <p>{`Sadly, he passed away on 2020.10.07. Based on the info I have, it appears it was due to COVID-19 or COVID-19 related factors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      